import { curry, pathSatisfies, test, identity, path } from "ramda";

const propFilter = curry((pathList, regex) => pathSatisfies(test(regex), pathList));

/**
 * break down all data retrieved in index.js
 */
export default function breakDownComponent(nodes, component) {
  const filterByFileName = propFilter(["fields", "fileName"]);

  // Component
  // const sectionsNodes = nodes.filter(filterByDirectoryName(/frontpage/i));
  /// We need a regex, and we are now adding the folder to read via parameter.
  var re = new RegExp(component, "i");
  const contentComponent = nodes.find(filterByFileName(re)) || {};

  return {
    contentComponent,
  };
}
